const static_url = document.getElementsByName('static_url')[0].defaultValue;

const static_images = {
  eugdpr: `${static_url}new-ui/assets/EU_GDPR.png`,
  iso27001: `${static_url}new-ui/assets/ISO_27001.png`,
  sally: `${static_url}new-ui/assets/sally.png`,
  sally_shadow: `${static_url}new-ui/assets/sally-shadow.png`,
  sally_dog: `${static_url}new-ui/assets/sally-dog.png`,
  sally_dog_shadow: `${static_url}new-ui/assets/sally-dog-shadow.png`,
  ybo_logo: `${static_url}new-ui/assets/ybo-logo.png`,
  ybo_icon: `${static_url}new-ui/assets/ybo-icon.png`,
};

export { static_images };
